






























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'
import { formatDateTimeString } from '@/utils/dateUtils'

@Component({
  components: {},
})
export default class List extends Vue {
  @Prop()
  private objectType: string

  @Prop()
  private mode: string

  private isLoading = false
  private data = []
  private limit = 0

  private formatTime(value): string {
    return formatDateTimeString(value)
  }

  private get limitMessage() {
    if (!this.limit || !this.data) {
      return ''
    }
    if (this.limit > this.data.length) {
      return ''
    }
    const msg = this.$t('Showing max %s items')
    return msg.replace('%s', '' + this.limit)
  }

  private mounted() {
    this.load()
  }

  private load() {
    if (!this.mode) {
      this.data = []
      return
    }
    this.isLoading = true
    const query = {
      mode: this.mode,
      objectType: null,
    }
    if (this.objectType) {
      query.objectType = this.objectType
    }
    this.$axios
      .get('/v4/site/integrations/accounting/status/items?' + this.toQueryString(query))
      .then((response) => {
        this.data = response.data.data?.items || []
        this.limit = parseInt(response.data.data?.limit) || 0
        this.isLoading = false
      })
      .catch((err) => {
        this.data = []
        vxm.alert.onAxiosError(err, 'Error fetching status items')
        this.isLoading = false
      })
  }

  private toQueryString(query): string {
    let result = ''
    for (const key in query) {
      if (query[key] !== null) {
        result += encodeURIComponent(key) + '=' + encodeURIComponent(query[key]) + '&'
      }
    }
    return result
  }

  private getRowClass(item): string {
    const status = item?.status?.toLowerCase() || ''
    return status ? 'status-' + status : ''
  }

  private getShortMessage(item): string {
    const message = item?.message || ''
    if (!message) {
      return ''
    }
    let firstLine = message.split('\n')[0]
    if (firstLine.length > 250) {
      firstLine = firstLine.substr(0, 250) + '...'
    }
    return firstLine
  }

  private getDetailsUrl(item) {
    return {
      name: 'Settings/Economy/AccountingIntegration/Status/Details',
      params: { id: item.transactionId },
    }
  }

  private clickNeverAttemptedItem() {
    alert('We have not yet attempted to transfer this item, so there are no details to look at')
  }

  private canShowAction(action, item) {
    switch (action) {
      case 'purge':
        return item.status === 'IN_PROGRESS' && item.transactionId
      case 'retry':
        return (
          (item.status === 'ERROR' || item.status === 'PARTIAL' || item.status === 'UNBLOCKED') && item.transactionId
        )
      case 'block':
        return (
          (item.status === 'ERROR' || item.status === 'PARTIAL' || item.status === 'UNBLOCKED' || !item.status) &&
          item.objectId &&
          item.objectType
        )
      case 'unblock':
        return item.status === 'BLOCKED' && item.objectId && item.objectType
      default:
        console.error('Unknown action: ' + action)
        return false
    }
  }

  private purgeItem(item) {
    const ok = confirm(
      this.$t(
        'Transaction is in progress and should only be stopped if it started a long time ago and has hung. Are you sure?',
      ),
    )
    if (!ok) {
      return
    }
    this.isLoading = true
    this.$axios
      .post('/v4/site/integrations/accounting/purge/' + item.transactionId)
      .then((_response) => {
        const index = this.data.indexOf(item)
        if (index > -1) {
          this.data.splice(index, 1)
        }
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error purging item')
        this.isLoading = false
      })
  }

  private toggleBlockItem(item, blocked) {
    const comment = prompt(
      this.$t('Provide a comment about why you are ' + (blocked ? 'blocking' : 'unblocking') + ' this item'),
    )
    if (!comment) {
      alert(this.$t('Must provide a comment'))
      return
    }
    this.isLoading = true
    let query = 'blocked=' + (blocked ? '1' : '')
    query +=
      '&objectId=' + item.objectId + '&objectType=' + item.objectType + '&comment=' + encodeURIComponent(comment)
    this.$axios
      .post('/v4/site/integrations/accounting/toggle-blocked?' + query)
      .then((_response) => {
        const index = this.data.indexOf(item)
        if (index > -1) {
          this.data.splice(index, 1)
        }
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error toggling blocked for item')
        this.isLoading = false
      })
  }

  private retryItem(item) {
    this.isLoading = true
    this.$axios
      .post('/v4/site/integrations/accounting/retry/' + item.transactionId)
      .then((response) => {
        item.retryAt = response.data.data.retryAt
        this.isLoading = false
      })
      .catch((err) => {
        this.isLoading = false
        vxm.alert.onAxiosError(err, 'Error retrying item')
      })
  }

  private retryAll(index = 0) {
    const item = this.data[index]
    if (!item) {
      this.isLoading = false
      return
    }
    this.isLoading = true
    this.$axios
      .post('/v4/site/integrations/accounting/retry/' + item.transactionId)
      .then((response) => {
        item.retryAt = response.data.data.retryAt
        this.retryAll(index + 1)
      })
      .catch((err) => {
        this.isLoading = false
        vxm.alert.onAxiosError(err, 'Error retrying all')
      })
  }
}
