
























import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import Overview from '@/components/economy/settings/accounting-integration/status/Overview.vue'
import List from '@/components/economy/settings/accounting-integration/status/List.vue'
import PageTab from '@/components/ui/PageTab.vue'
import { vxm } from '@/store'

@Component({
  components: {
    PageTab,
    Overview,
    List,
  },
})
export default class Status extends Vue {
  private integration = null
  private isLoading = false
  private tabs = []
  private defaultTabId = 'invoices'
  private internal = {
    tabId: '',
  }

  private get tabNames(): Array<string> {
    const result = []
    for (let i = 0; i < this.tabs.length; i++) {
      result.push(this.tabs[i].name)
    }
    return result
  }

  private get tab() {
    return this.getTabIndex(this.internal.tabId)
  }

  private mounted() {
    this.internal.tabId = this.defaultTabId
    this.loadIntegration(() => {
      this.tabs = [
        // { id: 'overview', name: 'Overview' },
        { id: 'invoices', name: 'Invoices' },
        { id: 'receipts', name: 'Receipts' },
        { id: 'recent', name: 'Recent' },
        { id: 'in-progress', name: 'In Progress' },
        { id: 'blocked', name: 'Blocked' },
      ]
      this.onRouteChange()
    })
  }

  private loadIntegration(callback) {
    this.isLoading = true
    this.$axios
      .get('/v4/site/integrations/accounting/configs')
      .then((response) => {
        this.integration = null
        const integrations = response.data.data || []
        for (let i = 0; i < integrations.length; i++) {
          if (integrations[i].status === 'ENABLED') {
            this.integration = integrations[i]
            break
          }
        }
        if (!this.integration && integrations.length > 0) {
          this.integration = integrations[0]
        }
        this.isLoading = false
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        this.isLoading = false
        vxm.alert.onAxiosError(err, 'Could not fetch integrations')
      })
  }

  private get receiptSendingMode() {
    if (this.integration) {
      return this.integration.receiptSendingMode
    }
    return ''
  }

  private getObjectType(tab: number): string {
    switch (this.getTabId(tab)) {
      case 'invoices':
        return 'Invoice'
      case 'receipts':
        switch (this.receiptSendingMode) {
          case 'DailyBatch':
          case 'DailyBatchWithSummedPayments':
            return 'RECEIPT_DAILY_BATCH'
          default:
            return 'RECEIPT'
        }
      default:
        return ''
    }
  }

  private getMode(tab: number): string {
    const id = this.getTabId(tab)
    switch (id) {
      case 'invoices':
      case 'receipts':
        return 'list'
      default:
        return id
    }
  }

  private getTabId(index: number): string {
    for (let i = 0; i < this.tabs.length; i++) {
      if (i === index) {
        return this.tabs[i].id
      }
    }
    console.error('No such tab index: ' + index)
    return ''
  }

  private getTabIndex(id: string): number {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].id === id) {
        return i
      }
    }
    console.error('No such tab id: ' + id)
    return 0
  }

  private onTabChange(index) {
    console.log('set tab:' + index)
    const tabId = this.getTabId(index)
    if (tabId !== this.internal.tabId) {
      this.navigate({
        tab: tabId,
      })
    }
  }

  private get isTabOverview() {
    return this.internal.tabId === 'overview'
  }

  private navigate(query) {
    if (!query) {
      query = {}
    }
    if (!query.tab) {
      query.tab = this.internal.tabId
    }
    console.log('Navigate: ' + JSON.stringify(query))
    this.$router.push({
      name: 'Settings/Economy/AccountingIntegration/Status',
      query: query,
    })
  }

  @Watch('$route', { deep: true })
  private onRouteChange() {
    this.internal.tabId = (this.$route.query?.tab as string) || this.defaultTabId
  }
}
