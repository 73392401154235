






import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  components: {},
})
export default class Overview extends Vue {
  foo() {
    console.log('todo')
  }
}
